<template>
  <div class="relationorder">
    <s-header :name="'关联商品'" :back="'/Applyhistory'"></s-header>
    <div class="content">
      <div class="order-item-box">
        <div class="order-item-header">
          <span>订单时间：{{ list.createTime }}</span>
          <span>{{ list.orderStatusString }}</span>
        </div>
        <van-card
          v-for="one in list.newBeeMallOrderItemVOS"
          :key="one.orderId"
          :num="one.goodsCount"
          :price="one.sellingPrice+'.00'"
          desc="全场包邮"
          :title="one.goodsName"
          :thumb="$filters.prefix(one.goodsCoverImg)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { useRoute } from 'vue-router'
import { reactive, toRefs, onMounted } from 'vue'
import { InvoiceOrderItem } from '@/service/apply'
export default {
  components: {
    sHeader
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      list: [],
      finished: false,
      loading: false
    })
    onMounted(() => {
      state.id = route.query.id
      loadData()
    })
    const loadData = () => {
      InvoiceOrderItem(state.id).then((res) => {
        state.list = res.data
      })
    }

    return {
      ...toRefs(state),
      loadData
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  height: calc(~'(100vh - 70px)');
  overflow: hidden;
  overflow-y: scroll;
}
.van-card__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.van-pull-refresh__head {
  background: #f9f9f9;
}
.order-item-box {
  margin: 0px 10px;
  background-color: #fff;
  .order-item-header {
    padding: 10px 20px 0 20px;
    display: flex;
    justify-content: space-between;
  }
  .van-card {
    background-color: #fff;
    margin-top: 0;
  }
}
</style>