<template>
  <div class="informatin">
    <div class="swiper-warp">
      <div class="informatin-logo">
        <!-- <div><img :src="logo" alt="" /></div> -->
        <div><img :src="newLogo" class="oiltour_lvxie_logo" alt="" /></div>
      </div>
      <!-- <div>
        <van-notice-bar
          left-icon="volume-o"
          color="#000"
          background="#eff3fe"
          text="今日油价：待开发..."
        />
      </div> -->
      <ul class="nav">
        <li v-for="item in navData" :key="item.index" @click="toUrl(item.url)">
          <img :src="item.icon" alt="" />
          <p>{{ item.name }}</p>
        </li>
        <div style="clear: both"></div>
      </ul>
      <div style="clear: both"></div>
    </div>

    <div class="informatin-content">
      <div class="content-banner">
        <img :src="img1" alt="" />
      </div>
      <div class="content-news">
        <div class="news-content">
          <div class="news-nav" @click="toUrl('news')">
            <div class="zaobao">
              油途早报<span style="margin-left: 5px">{{ time }}</span>
            </div>
            <div class="zhizhen">></div>
          </div>
          <div class="news-title">
            <van-swipe style="height: 50px" :autoplay="3000" vertical>
              <van-swipe-item
                v-for="item in newsData"
                @click="tocontent(item.id)"
                :key="item.index"
                >{{ item.contentTitle }}</van-swipe-item
              >
            </van-swipe>
          </div>
        </div>
      </div>
      <div class="content-service">
        <img :src="img2" alt="" />
      </div>

      <div class="content-list">
        <van-tabs v-model:active="status" color="#40c6ff" shrink>
          <van-tab
            v-for="item in list"
            :name="item.index"
            :key="item.index"
            :title="item.name"
          >
            <div class="news-warp" v-if="status == 0">
              <van-pull-refresh
                v-model="refreshing"
                @refresh="onRefresh"
                class="order-list-refresh"
              >
                <van-list
                  v-model:loading="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                  @offset="10"
                >
                  <div
                    class="news-list"
                    v-for="item in newsData"
                    :key="item.index"
                    @click="tocontent(item.id)"
                  >
                    <div class="news-left">
                      <h3 class="news-title">
                        {{ item.contentTitle }}
                      </h3>
                      <div class="news-describe">
                        {{ item.contentDescription }}
                      </div>
                      <div class="news-author">
                        <span
                          v-if="item.contentPinned == 1"
                          style="color: red; margin-right: 5px"
                          >置顶</span
                        >
                        <span>{{ item.contentAuthor }}</span>
                      </div>
                      <div class="news-time">
                        <span>{{ item.contentDatetime }}</span>
                      </div>
                      <div class="news-label">
                        <!-- <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag>
                    <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag> -->
                      </div>
                    </div>
                    <div class="news-img">
                      <img :src="item.contentImg" alt="" />
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
            </div>
            <div class="wk-warp" v-if="status == 1">
              <van-pull-refresh
                v-model="refreshing1"
                @refresh="onRefresh1"
                class="order-list-refresh"
              >
                <van-list
                  v-model:loading="loading1"
                  :finished="finished1"
                  finished-text="没有更多了"
                  @load="onLoad1"
                  @offset="10"
                >
                  <div class="wk-list" v-for="item in wkData" :key="item.index">
                    <van-card
                      @click="tolink(item.fileHtmlUrl)"
                      :title="item.title"
                      :thumb="item.coverDrawingUrl"
                    >
                      <!-- <template #bottom>
                    <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag>
                    <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag>
                  </template> -->
                      <template #tags>
                        <p>{{ item.publishTime }}</p>
                      </template>
                      <!-- <template #footer>
                    <van-button size="mini">按钮</van-button>
                    <van-button size="mini">按钮</van-button>
                  </template> -->
                    </van-card>
                  </div>
                </van-list>
              </van-pull-refresh>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { getLibrarys, queryPageContent } from '@/service/Information'
export default {
  setup() {
    const router = useRouter()
    const status = ref('')
    const state = reactive({
      img1: require('@/assets/img1.png'),
      img2: require('@/assets/img2.png'),
      logo: require('@/assets/logo.png'),
      newLogo: require('@/assets/oiltour_lvxie_logo.png'),
      logotitle: require('../assets/logotitle.png'),
      list: [{ name: '早报' }, { name: '文库' }],
      wkData: [],
      newsData: [],
      navData: [
        { name: '油途早报', icon: require('../assets/ytzb.png'), url: 'news' },
        { name: '油途文库', icon: require('../assets/ytwk.png'), url: 'wenku' },
        {
          name: '废油指导价',
          icon: require('../assets/fyzdj.png'),
          url: 'oilprice'
        },
        { name: '招投标', icon: require('../assets/ztb.png') },
        {
          name: '商务合作',
          icon: require('../assets/swhz.png'),
          url: 'cooperate'
        },
        { name: '社群', icon: require('../assets/sq.png') },
        { name: '互动', icon: require('../assets/hudong.png') },
        { name: '活动', icon: require('../assets/hd.png') }
      ],
      currPage: 1,
      totalPage: 0,
      loading: false,
      finished: false,
      refreshing: false,
      currPage1: 1,
      totalPage1: 0,
      loading1: false,
      finished1: false,
      refreshing1: false,
      time: ''
    })
    onMounted(() => {
      onRefresh()
      onRefresh1()

      //年
      let year = new Date().getFullYear()
      //月份是从0月开始获取的，所以要+1;
      let month = new Date().getMonth() + 1
      //日
      let day = new Date().getDate()
      state.time = year + '-' + month + '-' + day
    })

    const onChangeTab = (name) => {
      state.status = name
    }

    const newsList = async () => {
      const {
        data,
        data: { list }
      } = await queryPageContent({
        params: { categoryTitle: '早报 ', page: state.currPage, size: 10 }
      })
      state.newsData = state.newsData.concat(list)
      state.totalPage = data.totalPage
      state.loading = false
      if (state.currPage >= data.totalPage) state.finished = true
    }

    const wkList = async () => {
      const {
        data,
        data: { list }
      } = await getLibrarys({
        params: { pageNumber: state.currPage1, pageSize: 10 }
      })
      state.wkData = state.wkData.concat(list)
      state.totalPage1 = data.totalPage
      state.loading1 = false
      if (state.currPage1 >= data.totalPage) state.finished1 = true
    }

    const toUrl = (url) => {
      if (url) {
        router.push({ name: url })
      } else {
        Toast.fail({
          message: '开发中...',
          forbidClick: true
        })
      }
    }

    const tocontent = (id) => {
      router.push({ name: 'newscontent', query: { id, url: 'information' } })
    }

    const tolink = (url) => {
      window.open(url)
    }

    const onLoad = () => {
      if (!state.refreshing && state.currPage < state.totalPage) {
        state.currPage = state.currPage + 1
      }
      if (state.refreshing) {
        state.newsData = []
        state.refreshing = false
      }
      newsList()
    }

    const onRefresh = () => {
      state.refreshing = true
      state.finished = false
      state.loading = true
      state.currPage = 1
      onLoad()
    }

    const onLoad1 = () => {
      if (!state.refreshing1 && state.currPage1 < state.totalPage1) {
        state.currPage1 = state.currPage1 + 1
      }
      if (state.refreshing1) {
        state.newsData1 = []
        state.refreshing1 = false
      }
      wkList()
    }

    const onRefresh1 = () => {
      state.refreshing1 = true
      state.finished1 = false
      state.loading1 = true
      state.currPage1 = 1
      onLoad1()
    }
    return {
      ...toRefs(state),
      status,
      onChangeTab,
      toUrl,
      wkList,
      tolink,
      tocontent,
      onRefresh,
      onLoad,
      onLoad1,
      onRefresh1
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-tab {
  flex: 0 1 auto;
}
:deep(.van-swipe__indicators--vertical) {
  display: none;
}
:deep(.van-tab__text) {
  font-size: 12px;
}
:deep(.van-tab--active .van-tab__text) {
  font-size: 14px;
  font-weight: bold;
}
:deep(.van-tabs__line) {
  background-image: linear-gradient(90deg, #fff, #bbc9ed);
}
:deep(.van-card) {
  background: #fff;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #39a9ed;
}
.nav {
  padding: 0.4rem 0;
  li {
    img {
      width: 30px;
      height: 30px;
    }
    p {
      color: #000;
    }
  }
}
.swiper-warp {
  background-image: linear-gradient(180deg, #eff3fe, #eff3fe, #ffffff, #ffffff);
  color: #fff;
  .informatin-logo {
    padding: 0.4rem;
    height: 40px;
    img {
      position: relative;
      top: 15px;
    }
    .oiltour_lvxie_logo{
      width: 120px;
      height: 24px;
    }
  }
  .swiper-text {
    margin: 10px 0 10px 0;
    color: #ffffff;
    span {
      margin: 0 5px;
    }
  }
  .nav {
    li {
      width: 25%;
      text-align: center;
      float: left;
    }
  }
}

.informatin-content {
  padding: 0 0.4rem 0.4rem 0.4rem;
  .content-banner {
    height: 80px;
    background: #40c6ff;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .content-news {
    height: 90px;
    border: 1px solid #eff3fe;
    margin: 0.4rem 0;
    border-radius: 10px;
    .news-content {
      padding: 0.2rem;
      .news-nav {
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 20px;
        line-height: 20px;
        .zaobao {
          font-size: 0.5rem;
          font-weight: bold;
          span {
            font-size: 0.3rem;
            font-weight: 100;
          }
        }
        .zhizhen {
          color: #8a8585;
        }
      }
      .news-title {
        margin: 0.15rem 0;
        font-size: 0.3rem;
        font-weight: bold;
        position: relative;
        padding-left: 0.4rem;
      }
      .news-title::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 3px;
        border-style: solid;
        border-color: transparent;
        border-width: 5px 0 5px 5px;
        border-left-color: #40c6ff;
      }
    }
  }

  .content-service {
    height: 80px;
    background: #40c6ff;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .content-list {
    .news-warp {
      height: calc(~'(100vh - 50px)');
      overflow: hidden;
      overflow-y: scroll;
      .news-list {
        display: flex;
        justify-content: space-between;
        height: 110px;
        overflow: hidden;
        padding: 10px 0;
        border-bottom: 1px solid #c1c1c1;
        .news-left {
          width: 60%;
          margin-right: 10px;
          overflow: hidden;
          h3 {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box; //使用自适应布局
            -webkit-line-clamp: 2; //设置超出行数，要设置超出几行显示省略号就把这里改成几
            -webkit-box-orient: vertical;
          }
          .news-describe {
            margin: 2px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box; //使用自适应布局
            -webkit-line-clamp: 2; //设置超出行数，要设置超出几行显示省略号就把这里改成几
            -webkit-box-orient: vertical;
          }
          .news-author {
            color: #999;
            margin-bottom: 2px;
          }
          .news-time {
            color: #999;
          }
          .news-label {
            span {
              margin-right: 5px;
              border: none;
            }
          }
        }
        .news-img {
          width: 40%;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
      }
      .news-list:last-child {
        border: none;
      }
    }
    .wk-warp {
      height: calc(~'(100vh - 50px)');
      overflow: hidden;
      overflow-y: scroll;
      .wk-list {
        padding: 10px 0;
        border-bottom: 1px solid #c1c1c1;
      }
      .wk-lis:last-child {
        border: none;
      }
    }
  }
}
</style>