<template>
  <div class="applyinput">
    <s-header
      :name="!detailsShow ? '填写申请信息' : '核对发票信息'"
      :back="'/Apply'"
    ></s-header>
    <div v-if="!detailsShow">
      <div class="tab" style="display: flex; justify-content: space-around">
        <div
          :class="{ active: status == '3' }"
          style="color: #646566 !important"
          @click="onClickTab('3')"
        >
          <p>增值税普通电子发票</p>
        </div>
        <div
          :class="{ active: status == '1' }"
          style="color: #646566 !important"
          @click="onClickTab('1')"
        >
          <p>增值税专用发票</p>
        </div>
      </div>
      <!-- <van-tabs v-model:active="status" color="#40c6ff" @click="onClickTab"> -->
      <div title="增值税普通电子发票" v-if="status == '3'">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="formdata.price"
              readonly
              name="price"
              label="开票金额"
            />
            <p style="padding: 0 15px">
              <span style="color: #ef6614">注意事项：</span>
              根据相关政策规定，消费环节中各种形式的券、积分等涉及抵扣部分，不可开票
            </p>
            <div style="display: flex; height: 35px">
              <van-button
                color="#40c6ff"
                size="small"
                type="primary"
                style="margin: 0 10px"
                @click="searchBtn"
                >更换抬头
              </van-button>
              <van-search
                v-model="company"
                v-if="searchShow"
                style="padding: 0"
                show-action
                placeholder="请输入搜索关键词"
                @search="onSearch"
                @cancel="onCancel"
              />

              <van-popup v-model:show="showPicker" round position="bottom">
                <van-picker
                  title="抬头企业"
                  :columns="searchList"
                  @cancel="showPicker = false"
                  @confirm="companyPopup"
                />
              </van-popup>
            </div>
            <van-field
              v-model="formdata.purchaserName"
              required
              name="purchaserName"
              label="发票抬头"
              placeholder="更换抬头选择发票抬头"
              :rules="[{ required: true, message: '更换抬头选择发票抬头' }]"
            />
            <van-field
              v-model="formdata.purchaserTaxNo"
              required
              name="purchaserTaxNo"
              label="纳税人识别号"
              placeholder="更换抬头选择纳税人识别号"
              :rules="[{ required: true, message: '更换抬头选择发票抬头' }]"
            />
            <!-- <van-field
              v-model="formdata.purchaserEmail"
              name="purchaserEmail"
              label="收票人邮箱"
              placeholder="请填写收票人邮箱(选填)"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的邮箱'
                }
              ]"
            /> -->
            <van-field
              v-model="formdata.purchaserPhone"
              name="purchaserPhone"
              label="收票手机号"
              required
              placeholder="手机号码"
              :rules="[
                {
                  validator: telRules,
                  required: true,
                  message: '请输入正确手机号码'
                }
              ]"
            />
            <van-field
              v-model="formdata.Address"
              name="Address"
              label="单位地址"
              placeholder="公司地址(选填)"
              :rules="[{ required: false, message: '请填写地址' }]"
            />
            <van-field
              v-model="formdata.Phone"
              name="Phone"
              label="电话号码"
              placeholder="公司电话(选填)"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的手机号码'
                }
              ]"
            />
            <van-field
              v-model="formdata.bankAddress"
              name="bankAddress"
              label="开户银行"
              placeholder="开户银行(选填)"
              :rules="[{ required: false, message: '请填写开户银行' }]"
            />
            <van-field
              v-model="formdata.bankAccount"
              name="bankAccount"
              label="银行账户"
              placeholder="银行账户(选填)"
              :rules="[{ required: false, message: '请填写银行账号' }]"
            />
            <van-field
              v-model="formdata.remark"
              name="remark"
              label="备注说明"
              placeholder="备注说明(选填)"
              :rules="[{ required: false, message: '备注说明' }]"
            />
          </van-cell-group>
          <div style="margin: 16px">
            <van-button
              color="#40c6ff"
              round
              block
              type="primary"
              native-type="submit"
            >
              下一步
            </van-button>
          </div>
        </van-form>
      </div>
      <div title="增值税专用发票" v-if="status == '1'">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="formdata.price"
              readonly
              name="price"
              label="开票金额"
            />
            <p style="padding: 0 15px">
              <span style="color: #ef6614">注意事项：</span>
              根据相关政策规定，消费环节中各种形式的券、积分等涉及抵扣部分，不可开票
            </p>
            <div style="display: flex; height: 35px">
              <van-button
                color="#40c6ff"
                size="small"
                type="primary"
                style="margin: 0 10px"
                @click="searchBtn"
                >更换抬头
              </van-button>
              <van-search
                v-model="company"
                v-if="searchShow"
                style="padding: 0"
                show-action
                placeholder="请输入搜索关键词"
                @search="onSearch"
                @cancel="onCancel"
              />

              <van-popup v-model:show="showPicker" round position="bottom">
                <van-picker
                  title="抬头企业"
                  :columns="searchList"
                  @cancel="showPicker = false"
                  @confirm="companyPopup"
                />
              </van-popup>
            </div>
            <van-field
              v-model="formdata.purchaserName"
              required
              name="purchaserName"
              label="发票抬头"
              placeholder="更换抬头选择发票抬头"
              :rules="[{ required: true, message: '更换抬头选择发票抬头' }]"
            />
            <van-field
              v-model="formdata.purchaserTaxNo"
              required
              name="purchaserTaxNo"
              label="纳税人识别号"
              placeholder="更换抬头选择纳税人识别号"
              :rules="[{ required: true, message: '更换抬头选择发票抬头' }]"
            />
            <van-field
              v-model="formdata.purchaserPhone"
              name="purchaserPhone"
              label="收票手机号"
              required
              placeholder="手机号码"
              :rules="[
                {
                  validator: telRules,
                  required: true,
                  message: '请输入正确手机号码'
                }
              ]"
            />
            <van-field
              required
              v-model="formdata.Address"
              name="Address"
              label="单位地址"
              placeholder="公司地址"
              :rules="[{ required: true, message: '请填写地址' }]"
            />
            <van-field
              required
              v-model="formdata.Phone"
              name="Phone"
              label="电话号码"
              placeholder="公司电话"
              :rules="[
                {
                  validator: ghRules,
                  required: true,
                  message: '请填写公司电话'
                }
              ]"
            />
            <van-field
              required
              v-model="formdata.bankAddress"
              name="bankAddress"
              label="开户银行"
              placeholder="开户银行"
              :rules="[{ required: true, message: '请填写开户银行' }]"
            />
            <van-field
              v-model="formdata.bankAccount"
              name="bankAccount"
              required
              label="银行账户"
              placeholder="银行账户"
              :rules="[
                {
                  validator: accoundRules,
                  required: true,
                  message: '请填写正确的银行账号'
                }
              ]"
            />
            <van-field
              v-model="formdata.remark"
              name="remark"
              label="备注说明"
              placeholder="备注说明(选填)"
            />

            <!-- <van-field
              v-model="result"
              is-link
              readonly
              name="result"
              required
              label="地区选择"
              placeholder="点击选择省市区"
              :rules="[{ required: true, message: '请选择省市区' }]"
              @click="showArea = true"
            />
            <van-popup v-model:show="showArea" position="bottom">
              <van-area
                :area-list="areaList"
                @confirm="areaPopup"
                @cancel="showArea = false"
              />
            </van-popup>
            <van-field
              v-model="formdata.receiverAddress"
              name="receiverAddress"
              required
              label="详细地址"
              placeholder="请填写详细地址"
              :rules="[{ required: true, message: '请填写详细地址' }]"
            />
            <van-field
              v-model="formdata.receiverName"
              name="receiverName"
              required
              label="收票人姓名"
              placeholder="请填写收票人姓名"
              :rules="[
                {
                  validator: nameRules,
                  required: true,
                  message: '请填写正确的收票人姓名'
                }
              ]"
            />
            <van-field
              v-model="formdata.receiverPhone"
              name="receiverPhone"
              required
              label="收票人电话"
              placeholder="请填写收票人电话"
              :rules="[
                {
                  validator: telRules,
                  required: true,
                  message: '请填写正确的收票人电话'
                }
              ]"
            /> -->
          </van-cell-group>
          <div v-if="addressData" class="address-wrap">
            <h3 class="title">收货地址</h3>
            <div class="name" @click="goTo">
              <span>{{ addressData.userName }}</span>
              <span>{{ addressData.userPhone }}</span>
            </div>
            <div class="address">
              {{ addressData.provinceName }} {{ addressData.cityName }}
              {{ addressData.regionName }} {{ addressData.detailAddress }}
            </div>
            <van-icon class="arrow" name="arrow" />
          </div>
          <div style="margin: 16px">
            <van-button
              round
              block
              color="#40c6ff"
              type="primary"
              native-type="submit"
            >
              下一步
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <!-- </van-tabs> -->
    <div v-if="detailsShow" style="background: #fff">
      <h3 style="padding: 0 18px; font-size: 18px">
        {{ status === '3' ? '电子发票' : '纸质发票' }}
      </h3>
      <van-field
        v-if="formdata.price"
        v-model="formdata.price"
        readonly
        name="price"
        label="开票金额"
      />
      <van-field
        v-if="formdata.purchaserName"
        v-model="formdata.purchaserName"
        readonly
        name="purchaserName"
        label="发票抬头"
      />
      <van-field
        v-if="formdata.purchaserTaxNo"
        v-model="formdata.purchaserTaxNo"
        readonly
        name="purchaserTaxNo"
        label="纳税人识别号"
      />
      <van-field
        v-if="formdata.purchaserPhone"
        v-model="formdata.purchaserPhone"
        readonly
        name="purchaserPhone"
        label="收票手机号"
      />
      <van-field
        v-if="formdata.Address"
        v-model="formdata.Address"
        readonly
        name="Address"
        label="单位地址"
      />
      <van-field
        v-if="formdata.Phone"
        v-model="formdata.Phone"
        readonly
        name="Phone"
        label="电话号码"
      />
      <van-field
        v-if="formdata.bankAddress"
        v-model="formdata.bankAddress"
        readonly
        name="bankAddress"
        label="开户银行"
      />
      <van-field
        v-if="formdata.bankAccount"
        v-model="formdata.bankAccount"
        readonly
        name="bankAccount"
        label="银行账户"
      />
      <van-field
        v-if="formdata.remark"
        v-model="formdata.remark"
        readonly
        name="remark"
        label="备注说明"
      />
      <!-- <van-field
      v-if="result"
      v-model="result"
      readonly
      name="result"
      label="地区"
    />
    <van-field
      v-if="formdata.receiverAddress"
      v-model="formdata.receiverAddress"
      readonly
      name="receiverAddress"
      label="详细地址"
    />
    <van-field
      v-if="formdata.receiverName"
      v-model="formdata.receiverName"
      readonly
      name="receiverName"
      label="收票人姓名"
    />
    <van-field
      v-if="formdata.receiverPhone"
      v-model="formdata.receiverPhone"
      readonly
      name="receiverPhone"
      label="收票人电话"
    /> -->
      <div style="display: flex; margin: 16px">
        <van-button
          round
          block
          type="default"
          style="margin: 0 10px"
          @click="step"
        >
          上一步
        </van-button>
        <van-button
          round
          block
          color="#40c6ff"
          type="primary"
          native-type="submit"
          style="margin: 0 10px"
          @click="submitFrom"
        >
          提交
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { reactive, toRefs, ref, onMounted } from 'vue'
import { areaList } from '@vant/area-data'
import { useRoute, useRouter } from 'vue-router'
import { getUserTaxNo, getTaxNoByCompanyName, invoice } from '@/service/apply'
import { Toast, Dialog } from 'vant'
import { getDefaultAddress, getAddressDetail } from '@/service/address'
import { setLocal, getLocal } from '@/common/js/utils'

export default {
  components: {
    sHeader
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const result = ref('')
    const showArea = ref(false)
    const emailRules = (val) =>
      /^[A-Za-z0-9-_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
        val
      )
    const telRules = (val) =>
      /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(val)
    const accoundRules = (val) => /^[0-9]*$/.test(val)
    const nameRules = (val) => /^[\u4e00-\u9fa5]{0,}$/.test(val)
    const ghRules = (val) =>
      /^(0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/.test(val)
    const state = reactive({
      status: '3',
      formdata: {
        Address: null,
        Phone: null,
        bankAddress: null,
        bankAccount: null
      }, //表单数据
      searchShow: false, //抬头搜索隐藏
      company: '', //搜索公司名称
      searchList: [], //搜索列表
      showPicker: false,
      detailsShow: false,
      directInv: 1,
      addressData: {}
    })
    onMounted(() => {
      const { status } = route.query
      if (status) {
        state.status = status
        state.detailsShow = false
        getAddress()
      }
      state.formdata.price = route.query.price
      state.invoiceOrderInfos = JSON.parse(route.query.invoiceOrderInfos)
      if (getLocal('fromData')) {
        state.formdata = JSON.parse(getLocal('fromData'))
      }
      if (getLocal('company') || getLocal('taxNo')) {
        if (!getLocal('fromData')) {
          state.formdata.purchaserTaxNo = getLocal('taxNo')
          state.formdata.purchaserName = getLocal('company')
        }
      } else {
        getUser()
      }
    })
    const getUser = () => {
      if (getLocal('company') || getLocal('taxNo')) {
        state.formdata.purchaserTaxNo = getLocal('taxNo')
        state.formdata.purchaserName = getLocal('company')
      } else {
        getUserTaxNo().then((res) => {
          if (res.data.name === null || res.data.taxNo === null) {
            setLocal('taxNo', res.data.taxNo)
            setLocal('company', res.data.company)
            state.formdata.purchaserTaxNo = res.data.taxNo
            state.formdata.purchaserName = res.data.company
          }
        })
      }
    }
    const getAddress = async () => {
      const { addressId } = route.query
      const { data: address } = addressId
        ? await getAddressDetail(addressId)
        : await getDefaultAddress()
      state.addressData = address
      if (!address) {
        Dialog.confirm({
          title: '温馨提示',
          message: '专用发票为纸质发票，请先添加收货地址！',
          confirmButtonText: '添加收货地址'
        })
          .then(() => {
            router.push({
              path: '/address',
              query: {
                price: state.formdata.price,
                invoiceOrderInfos: route.query.invoiceOrderInfos,
                from: 'applyinput',
                status: state.status
              }
            })
            return
          })
          .catch(() => {
            router.push({ path: '/Apply' })
            return
          })
        return
      }
    }
    const areaPopup = (areaValues) => {
      showArea.value = false
      result.value = areaValues
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
    }
    const onClickTab = (name) => {
      state.formdata = {
        Address: null,
        Phone: null,
        bankAddress: null,
        bankAccount: null
      }
      state.status = name
      state.formdata.price = route.query.price
      state.searchShow = false
      getUser()
      if (name == 1) {
        getAddress()
      }
    }
    //更换抬头
    const searchBtn = () => {
      state.searchShow = true
    }

    //搜索
    const onSearch = (val) => {
      if (val.length < 5) {
        Toast.fail('公司名称长度最小为5')
      } else {
        getTaxNoByCompanyName({ companyName: val }).then((res) => {
          if (res.resultCode === 200) {
            if (res.data.length < 1) {
              Toast.fail('未找到该公司')
            } else {
              state.searchList = res.data.map((item) => {
                return { text: item.company, cities: null, value: item.taxNo }
              })
              state.showPicker = true
            }
          }
        })
      }
    }
    const goTo = () => {
      setLocal('fromData', JSON.stringify(state.formdata))
      router.push({
        path: '/address',
        query: {
          price: state.formdata.price,
          invoiceOrderInfos: route.query.invoiceOrderInfos,
          from: 'applyinput',
          status: state.status
        }
      })
    }
    //取消搜索
    const onCancel = () => {
      state.searchShow = false
    }

    //发票抬头选择
    const companyPopup = (val) => {
      state.formdata.purchaserTaxNo = val.value
      state.formdata.purchaserName = val.text
      state.directInv = 0

      state.showPicker = false
      state.searchShow = false
    }
    const step = () => {
      state.formdata.price = route.query.price
      state.detailsShow = false
      getUser()
    }
    const onSubmit = (val) => {
      if (val.purchaserEmail && !emailRules(val.purchaserEmail))
        Toast.fail('请输入正确的邮箱地址')
      else if (val.purchaserPhone && !telRules(val.purchaserPhone))
        Toast.fail('请输入正确的电话号码')
      else if (val.bankAccount && !accoundRules(val.bankAccount))
        Toast.fail('请输入正确的银行账户')
      else {
        setLocal('taxNo', val.purchaserTaxNo)
        setLocal('company', val.purchaserName)
        state.detailsShow = true
      }
    }
    const submitFrom = () => {
      const {
        Address,
        Phone,
        purchaserEmail,
        purchaserName,
        purchaserPhone,
        purchaserTaxNo,
        bankAccount,
        bankAddress,
        remark
      } = state.formdata
      const {
        userName,
        userPhone,
        provinceName,
        cityName,
        regionName,
        detailAddress
      } = state.addressData
      const obj = {
        directInv: state.directInv,
        invoiceOrderInfos: state.invoiceOrderInfos,
        invoiceType: state.status,
        receiverAddress:
          state.status == '1'
            ? provinceName + cityName + regionName + detailAddress
            : null,
        receiverPhone: state.status == '1' ? userPhone : null,
        receiverName: state.status == '1' ? userName : null,
        purchaserAddress: Address + ' ' + Phone || null,
        purchaserBankName: bankAddress + ' ' + bankAccount || null,
        purchaserEmail,
        purchaserName,
        purchaserPhone,
        purchaserTaxNo,
        remark
      }
      Toast.loading({ message: '开票中...', forbidClick: true })
      invoice(obj).then((res) => {
        if (res.resultCode == 200) {
          if (state.status == '1') {
            Dialog.alert({
              message:
                '已提交申请,纸质发票开具及邮寄时间为3-5个工作日,请留意开票历史页面。'
            }).then(() => {
              router.push({
                path: '/user'
              })
            })
          } else {
            Dialog.alert({
              message: '已提交开票申请,请留意开票历史页面。'
            }).then(() => {
              router.push({
                path: '/user'
              })
            })
          }
        }
      })
    }
    return {
      ...toRefs(state),
      onClickTab,
      result,
      showArea,
      areaList,
      getAddress,
      ghRules,
      getUser,
      emailRules,
      telRules,
      accoundRules,
      nameRules,
      areaPopup,
      searchBtn,
      onSearch,
      onCancel,
      companyPopup,
      onSubmit,
      step,
      goTo,
      submitFrom
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  div {
    p {
      text-align: center;
      color: #646566;
      height: 40px;
      margin: 0;
      line-height: 40px;
      font-size: 14px;
      position: relative;
    }
  }
  .active p {
    color: #646566;
  }
  .active p::before {
    content: '';
    position: absolute;
    left: 40%;
    width: 30px;
    height: 2px;
    bottom: 0;
    background: #40c6ff;
  }
}

.applyinput {
  .address-wrap {
    margin-bottom: 20px;
    background: #fff;
    position: relative;
    font-size: 14px;
    padding: 0 15px;
    color: #222333;
    .name,
    .address {
      margin: 10px 0;
    }
    .arrow {
      position: absolute;
      right: 10px;
      top: 70%;
      transform: translateY(-50%);
      font-size: 20px;
    }
  }
  .formtab {
    display: flex;
    justify-content: space-around;
  }
}
</style>