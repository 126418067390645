<template>
  <div class="applyhistory">
    <s-header :name="'开票历史'" :back="'/user'"></s-header>
    <div class="historycontent">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        class="order-list-refresh"
      >
        <van-list
          v-model:loading="loading"
          finished-text="没有更多了"
          :finished="finished"
          @load="onLoad"
          @offset="50"
        >
          <div class="history" v-for="(item, index) in list" :key="index">
            <div class="top">
              {{ item.invoiceType === 3 ? '普通发票' : '专用发票' }}({{
                item.invoiceType === 3 ? '电子发票' : '纸质发票'
              }})
            </div>
            <div
              v-if="item.invoiceStatus === 10"
              style="color: #40c6ff"
              class="remarks"
            >
              已开票
            </div>
            <div
              v-else-if="item.invoiceStatus == null"
              style="color: #19ca6c"
              class="remarks"
            >
              开票中
            </div>
            <div
              v-else-if="item.invoiceStatus === 20"
              style="color: red"
              class="remarks"
            >
              已红冲
            </div>

            <div class="content">
              <div class="contenttit">
                <div>
                  <span class="left">发票金额：</span
                  ><span class="price">¥{{ item.amount }}</span>
                </div>
              </div>
              <div>
                <span class="left">发票代码：</span>{{ item.invoiceCode }}
              </div>
              <div>
                <span class="left">发票号码：</span>{{ item.invoiceNo }}
              </div>
              <div>
                <span class="left">申请时间：</span>{{ item.createTime }}
              </div>
            </div>
            <div class="button">
              <!-- <van-button
            type="warning"
            size="small"
            @click="goTo('/Applyreopen', { id: item.id })"
            >发票红冲重开</van-button
          > -->
              <van-button
                v-if="item.pdfUrl"
                type="warning"
                size="small"
                @click="openUrl(item.pdfUrl)"
                >查看发票</van-button
              >
              <van-button
                color="#40c6ff"
                type="primary"
                size="small"
                @click="goTo('/Relationorder', { id: item.invoiceNo })"
                >关联商品</van-button
              >
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-if="!list" class="empty">
      <img
        class="empty-cart"
        src="https://s.yezgea02.com/1604028375097/empty-car.png"
        alt="空购物车"
      />
      <div class="title">当前没有已开票的订单</div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import sHeader from '@/components/SimpleHeader'
import { getinvoice } from '@/service/apply'
import { Dialog, Toast } from 'vant'

export default {
  components: {
    sHeader
  },

  setup() {
    const router = useRouter()
    const state = reactive({
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      page: 1,
      totalPage: 0
    })
    const goTo = (r, query) => {
      router.push({ path: r, query: query || {} })
    }

    onMounted(() => {
      onRefresh()
    })
    const loadData = async () => {
      const {
        data,
        data: { list }
      } = await getinvoice({ pageNumber: state.page })
      state.list = state.list.concat(list)
      state.totalPage = data.totalPage
      state.loading = false
      if (state.page >= data.totalPage) state.finished = true
    }
    const onLoad = () => {
      console.log(state.page, state.totalPage)
      if (!state.refreshing && state.page < state.totalPage) {
        state.page = state.page + 1
      }
      if (state.refreshing) {
        state.list = []
        state.refreshing = false
      }
      loadData()
    }
    const onRefresh = () => {
      state.refreshing = true
      // 清空列表数据
      state.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      state.loading = true
      state.page = 1
      onLoad()
    }
    const copyWx = (item) => {
      // 模拟 输入框
      var cInput = document.createElement('input')
      cInput.value = item
      document.body.appendChild(cInput)
      cInput.select() // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand('copy')
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput)
    }
    const openUrl = (url) => {
      Dialog.alert({
        title: '提示',
        message: '请复制链接到手机浏览器预览' + url,
        confirmButtonText: '点击复制'
      }).then(() => {
        copyWx(url)
        Toast.success('复制成功')
        // router.push({
        //   path: '/user'
        // })
      })
      // window.open(url)
    }
    return {
      ...toRefs(state),
      goTo,
      openUrl,
      onLoad,
      onRefresh
    }
  }
}
</script>

<style lang="less" scoped>
.applyhistory {
  background: #f9f9f9;
  .historycontent {
    background: #ffffff;
    height: calc(~'(100vh - 50px)');
    overflow: auto;
  }
  .history {
    margin: 10px;
    padding: 10px;
    position: relative;
    background: #ffffff;
    border-radius: 0.4rem;
    box-shadow: 0 0.05333rem 0.13333rem #e7e7e7;
    .top {
      font-weight: bold;
      font-size: 16px;
      line-height: 40px;
      border-bottom: 1px solid #ecf0f2;
      color: #333333;
    }
    .remarks {
      position: absolute;
      top: 20px;
      right: 10px;
      padding: 1px 4px;
      font-size: 14px;
    }
    .content {
      line-height: 25px;
      font-size: 14px;
      div {
        height: 40px;
        line-height: 40px;
      }
      .left {
        color: #999999;
      }
      .contenttit {
        display: flex;
        justify-content: space-between;
        .type {
          font-weight: bold;
          font-size: 14px;
        }
        .price {
          color: #ff524d;
        }
      }
    }
    .button {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      button {
        width: 45%;
      }
    }
  }
  .empty {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    margin-top: 200px;
    .empty-cart {
      width: 150px;
      margin-bottom: 20px;
    }
    .van-icon-smile-o {
      font-size: 50px;
    }
    .title {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
</style>