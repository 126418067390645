<template>
  <div>
    <s-header :name="'我的优惠券'"></s-header>
    <van-tabs v-model:active="activeName" @click="onChangeTab">
      <van-tab title="可领取" name="a">
        <div class="discount-list">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            @offset="50"
          >
            <div
              v-for="(item, index) in couponsList"
              :key="index"
              class="discount-box"
            >
              <div style="display: flex">
                <div class="discount-money">
                  <h1 v-if="item.couponType !== 'POINT'">
                    {{ item.price }}<span class="size12">元</span>
                  </h1>
                  <h1 v-if="item.couponType === 'POINT'">
                    {{ item.couponDiscount }}<span class="size12">折</span>
                  </h1>
                  <p>{{ item.briefIntroduction }}</p>
                </div>
                <div class="discount-title">
                  <div class="title">
                    <span v-if="item.couponType === 'POINT'">折扣券</span>
                    <span v-if="item.couponType === 'PRICE'">抵扣券</span
                    ><span v-if="item.couponType === 'REWARD'">奖励券</span
                    >{{ item.couponName }}
                  </div>
                  <div class="date">
                    {{ item.startTime.substr(0, 10) }} -
                    {{ item.endTime.substr(0, 10) }}
                    <div
                      :class="item.num >= 1 ? 'go' : 'null'"
                      @click="goDiscount(item)"
                    >
                      {{ item.num >= 1 ? '领取' : '已领取' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="discount-remarks" @click="showPopup(index)">
                <van-icon name="arrow-down" />
                <p>仅限指定类目购买</p>
                <transition name="van-fade">
                  <div class="remarks" v-show="isshow === index">
                    <!-- <div>领取时间：2022.03.03 00：00：00</div> -->
                    <span v-html="item.description"></span>
                  </div>
                </transition>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="抵扣券" name="b">
        <div class="discount-list">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            @offset="10"
          >
            <div
              v-for="(item, index) in deductionList"
              :key="index"
              class="discount-box"
            >
              <div class="used" v-if="item.memberCouponStatus === 'USED'">
                已使用
              </div>
              <div style="display: flex">
                <div class="discount-money">
                  <h1>{{ item.price }}<span class="size12">元</span></h1>
                  <p>{{ item.briefIntroduction }}</p>
                </div>
                <div class="discount-title">
                  <div class="title">
                    <span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'POINT'"
                      >折扣券</span
                    >
                    <span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'PRICE'"
                      >抵扣券</span
                    ><span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'REWARD'"
                      >奖励券</span
                    >{{ item.couponName }}
                  </div>
                  <div class="date">
                    {{ item.startTime.substr(0, 10) }} -
                    {{ item.endTime.substr(0, 10) }}
                  </div>
                </div>
              </div>
              <div class="discount-remarks" @click="showPopup(index)">
                <van-icon name="arrow-down" />
                <p>仅限指定类目购买</p>
                <transition name="van-fade">
                  <div class="remarks" v-show="isshow === index">
                    <!-- <div>领取时间：2022.03.03 00：00：00</div> -->
                    <span v-html="item.description"></span>
                  </div>
                </transition>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="奖励券" name="c">
        <div class="discount-list">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            @offset="10"
          >
            <div
              v-for="(item, index) in rewardList"
              :key="index"
              class="discount-box"
            >
              <div class="used" v-if="item.memberCouponStatus === 'USED'">
                已使用
              </div>
              <div style="display: flex">
                <div class="discount-money">
                  <h1>{{ item.price }}<span class="size12">元</span></h1>
                  <p>{{ item.briefIntroduction }}</p>
                </div>
                <div class="discount-title">
                  <div class="title">
                    <span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'POINT'"
                      >折扣券</span
                    >
                    <span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'PRICE'"
                      >抵扣券</span
                    ><span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'REWARD'"
                      >奖励券</span
                    >{{ item.couponName }}
                  </div>
                  <div class="date">
                    {{ item.startTime.substr(0, 10) }} -
                    {{ item.endTime.substr(0, 10) }}
                  </div>
                </div>
              </div>
              <div class="discount-remarks" @click="showPopup(index)">
                <van-icon name="arrow-down" />
                <p>仅限指定类目购买</p>
                <transition name="van-fade">
                  <div class="remarks" v-show="isshow === index">
                    <!-- <div>领取时间：2022.03.03 00：00：00</div> -->
                    <span v-html="item.description"></span>
                  </div>
                </transition>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="折扣券" name="d">
        <div class="discount-list">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            @offset="10"
          >
            <div
              v-for="(item, index) in discountList"
              :key="index"
              class="discount-box"
            >
              <div class="used" v-if="item.memberCouponStatus === 'USED'">
                已使用
              </div>
              <div style="display: flex">
                <div class="discount-money">
                  <h1>
                    {{ item.couponDiscount }}<span class="size12">折</span>
                  </h1>
                  <p>{{ item.briefIntroduction }}</p>
                </div>
                <div class="discount-title">
                  <div class="title">
                    <span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'POINT'"
                      >折扣券</span
                    >
                    <span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'PRICE'"
                      >抵扣券</span
                    ><span
                      :style="
                        item.memberCouponStatus === 'USED'
                          ? 'background: #999999;'
                          : ''
                      "
                      v-if="item.couponType === 'REWARD'"
                      >奖励券</span
                    >{{ item.couponName }}
                  </div>
                  <div class="date">
                    {{ item.startTime.substr(0, 10) }} -
                    {{ item.endTime.substr(0, 10) }}
                  </div>
                </div>
              </div>
              <div class="discount-remarks" @click="showPopup(index)">
                <van-icon name="arrow-down" />
                <p>仅限指定类目购买</p>
                <transition name="van-fade">
                  <div class="remarks" v-show="isshow === index">
                    <!-- <div>领取时间：2022.03.03 00：00：00</div> -->
                    <span v-html="item.description"></span>
                  </div>
                </transition>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { getReceivable, getReceive, getConlist } from '@/service/discount'
import sHeader from '@/components/SimpleHeader'
import { Toast } from 'vant'

export default {
  components: {
    sHeader
  },
  setup() {
    const state = reactive({
      couponsList: [],
      deductionList: [],
      rewardList: [],
      discountList: [],
      loading: false,
      finished: false,
      total: 0,
      page: 1,
      size: 10,
      status: 'a',
      couponType: 'PRICE',
      refreshing: false
    })
    const activeName = ref('a')
    const isshow = ref(-1)

    const showPopup = (index) => {
      if (isshow.value === index) {
        isshow.value = -1
      } else {
        isshow.value = index
      }
    }
    const goDiscount = (val) => {
      getReceive(val.couponId, val.activityId)
        .then((res) => {
          Toast(res.message)
          if (res.data === 0) {
            state.couponsList.forEach((item) => {
              if (val.couponId === item.couponId) {
                item.num = 0
              }
            })
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }
    const onChangeTab = (name) => {
      state.status = name
      state.loading = true
      state.finished = false
      state.page = 1
      if (name == 'a') {
        state.couponsList = []
        onLoad()
      }
      if (name == 'b') {
        state.deductionList = []
        state.couponType = 'PRICE'
        onLoad()
      }
      if (name == 'c') {
        state.rewardList = []
        state.couponType = 'REWARD'
        onLoad()
      }
      if (name == 'd') {
        state.discountList = []
        state.couponType = 'POINT'
        onLoad()
      }
    }
    const onLoad = () => {
      if (state.status === 'a') {
        getReceivable(state.size, state.page).then((res) => {
          if (state.page < res.data.pages) {
            state.page = state.page + 1
          }
          state.couponsList.push(...res.data.records)
          state.loading = false
          if (state.couponsList.length >= res.data.total) state.finished = true
        })
      }
      if (state.status === 'b') {
        getConlist(state.couponType, state.size, state.page).then((res) => {
          if (state.page < res.data.pages) {
            state.page = state.page + 1
          }
          state.deductionList.push(...res.data.records)
          state.loading = false
          if (state.deductionList.length >= res.data.total)
            state.finished = true
        })
      }
      if (state.status === 'c') {
        getConlist(state.couponType, state.size, state.page).then((res) => {
          if (state.page < res.data.pages) {
            state.page = state.page + 1
          }
          state.rewardList.push(...res.data.records)
          state.loading = false
          if (state.rewardList.length >= res.data.total) state.finished = true
        })
      }
      if (state.status === 'd') {
        getConlist(state.couponType, state.size, state.page).then((res) => {
          if (state.page < res.data.pages) {
            state.page = state.page + 1
          }
          state.discountList.push(...res.data.records)
          state.loading = false
          if (state.discountList.length >= res.data.total) state.finished = true
        })
      }
    }
    return {
      ...toRefs(state),
      onLoad,
      isshow,
      activeName,
      onChangeTab,
      showPopup,
      goDiscount
    }
  }
}
</script>

<style lang="less" scoped>
.size12 {
  font-size: 12px;
}
.discount-list {
  height: calc(~'(100vh - 100px)');
  padding: 10px 20px;
  overflow: auto;
  .discount-box {
    background: #ffffff;
    box-shadow: 0 2px 10px #e7e7e7;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    .used {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: left;
      border-radius: 50%;
      color: #fff;
      background: #999999;
      border: 1px #999999 solid;
      position: absolute;
      right: -5px;
      top: -5px;
    }
    .discount-money {
      text-align: center;
      width: 30%;
      border-right: 1px dashed #ecc5c5;
      h1 {
        margin: 15px 0 0 0;
        font-size: 30px;
      }
      p {
        margin: 0;
      }
    }

    .discount-title {
      width: 70%;
      .title {
        padding: 25px 10px 0 10px;
        span {
          background: #e60b30;
          color: #ffffff;
          padding: 2px 4px;
          margin-right: 5px;
        }
      }
      .date {
        position: relative;

        padding: 10px 10px;
        color: #999999;
      }
      .go {
        background: #e60b30;
        color: #ffffff;
        height: 20px;
        width: 50px;
        line-height: 21px;
        text-align: center;
        border-radius: 15px;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
      .null {
        background: #999;
        color: #ffffff;
        height: 20px;
        width: 50px;
        line-height: 21px;
        text-align: center;
        border-radius: 15px;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
    .discount-remarks {
      width: 100%;
      position: relative;
      border-top: 1px dashed #ecc5c5;
      i {
        position: absolute;
        font-size: 14px;
        right: 10px;
        top: 7px;
      }
      p {
        margin: 0;
        padding: 5px 10px;
        font-weight: bold;
      }
      .remarks {
        background: #fff;
        padding: 10px;
        border-top: 1px dashed #ecc5c5;
        ul li {
          font-weight: 500;
          margin: 5px 0;
        }
      }
    }
  }
}
</style>