<template>
  <div class="applyreopen">
    <s-header :name="'发票红冲重开'" :back="'/Applyhistory'"></s-header>
    <div class="reopen">
      <div class="reopenlist">
        <div class="listtype">
          <div>请选择重开类型</div>
        </div>
        <van-radio-group
          v-model="checkedtype"
          class="listradio"
          direction="horizontal"
        >
          <van-radio checked-color="#40c6ff" name="1">修改发票信息</van-radio>
          <van-radio checked-color="#40c6ff" name="2">重选订单开票</van-radio>
        </van-radio-group>
      </div>
      <div class="reopenlist">
        <div class="listtype">
          <div>请选择重开原因</div>
        </div>
        <van-radio-group
          v-model="checkedreason"
          class="listradio"
          direction="horizontal"
        >
          <van-radio checked-color="#40c6ff" name="1">开票金额错误</van-radio>
          <van-radio checked-color="#40c6ff" name="2">其他</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="remarks">
      <div style="color: #ef6614">注意事项</div>
      <div>1、确认红冲后，原发票将被红冲，不能在用于报销；</div>
      <div>2、发票红冲成功后，可重新选择订单申请发票；</div>
      <div>3、专票申请红冲前请确认未进行抵扣。</div>
    </div>
    <div class="bottom">
      <div class="tel">
        <a href="tel:4009013838">
          <van-icon name="phone-o" color="#40c6ff" size="22px" />
          <p>电话</p>
        </a>
      </div>
      <div class="submit">
        <van-button round color="#40c6ff" type="primary" @click="sub"
          >提交申请</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import sHeader from '@/components/SimpleHeader'
import { redInvoice } from '@/service/apply'

export default {
  components: {
    sHeader
  },
  setup() {
    const route = useRoute()

    const state = reactive({
      checkedtype: '1',
      checkedreason: '1',
      id: ''
    })

    onMounted(() => {
      state.id = route.query.id
    })
    const sub = () => {
      const data = redInvoice(state.id)
      console.log(data)
    }
    return {
      ...toRefs(state),
      sub
    }
  }
}
</script>

<style lang="less">
.applyreopen {
  background: #f9f9f9;
  .reopen {
    background: #fff;
    padding: 10px;
    .listtype {
      padding: 10px 0;
      font-size: 18px;
    }
    .van-radio {
      // width: 100px;
      // padding: 10px 20px;
      // border-radius: 86px 86px 86px 86px;
      // opacity: 1;
      // border: 1px solid #e0e1e6;
      // .van-radio__icon {
      //   display: none;
      // }
      // .van-radio__label {
      //   margin: 0;
      // }
    }
    .listradio {
      font-size: 14px;
      // justify-content: space-around;
    }
  }
  .remarks {
    line-height: 25px;
    padding: 10px;
  }
  .bottom {
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 10px;
    .tel {
      p {
        margin: 0;
        color: #40c6ff;
      }
      margin-right: 15px;
    }
    .submit {
      width: calc(100% - 50px);
      button {
        width: 100%;
      }
    }
  }
}
</style>