import axios from '../utils/axios'

export function getReceivable(pageSize, pageNumber) {
  return axios.get('/coupon/receivable?pageSize=' + pageSize + '&pageNumber=' + pageNumber);
}

export function getConlist(couponType, pageSize,pageNumber) {
  return axios.get('/coupon/list?order=asc&sort=memberCouponStatus&couponType=' + couponType + '&pageSize=' + pageSize+'&pageNumber=' + pageNumber);
}


export function getReceive(couponId, activityId) {
  return axios.get('/coupon/receive/' + couponId + '/' + activityId);
}